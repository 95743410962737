export const CREATE_ORDER = `
mutation createOrder(
    $tenant_id: Int! 
    $input: CreateOrderInput! 
    $lines: [CreateOrderLineInput!]
  ){
    createOrder(
      tenant_id:$tenant_id
      input:$input
      lines:$lines
    ){
      __typename
      ...on Order{
        id
      }
      ...on ResultError{
        status_code
        message
        message_translation_key
      }
    }
  }
`;

export const DELETE_ORDER = `
mutation deleteOrder(
  $id: Int!, 
  $tenant_id: Int!
){
deleteOrder(
  id:$id, 
  tenant_id:$tenant_id
){
  __typename
  ...on Order{
    id      
  }
  ...on ResultError{
    status_code
    message
    message_translation_key
  }
}
}
`;

export const UPDATE_ORDER = `
mutation updateOrder(
  $tenant_id: Int!
  $input: UpdateOrderInput!
){
updateOrder(
  tenant_id:$tenant_id
  input:$input
){
  __typename
  ...on Order{
    id
  }
  ...on ResultError{
    status_code
    message
    message_translation_key
  }
}
}
`;

export const UPDATE_ORDER_TOTAL = `
mutation updateOrderTotal(
  $tenant_id: Int!,
  $input: UpdateTotalInput!
){
updateOrderTotal(
  tenant_id:$tenant_id,
  input:$input
){
  __typename
  ...on Order{
    id      
  }
  ...on ResultError{
    status_code
    message
    message_translation_key
  }
}
}
`;

export const REJECT_ORDER = `
mutation rejectOrder(
  $tenant_id: Int!
  $input:MultipleActionOnOrdersInput!
){
  rejectOrder(
    tenant_id:$tenant_id,
    input:$input
  ){
    __typename
    ...on Order{
      id
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const CONFIRM_ORDER = `
mutation confirmOrder(
  $tenant_id: Int!
  $input: MultipleActionOnOrdersInput!
){
  confirmOrder(
    tenant_id:$tenant_id
    input:$input    
  ){
    __typename
    ...on Order{
      id,
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;
export const REGISTER_PAYMENT_ORDER = `
mutation registerPaymentOrder(
  $tenant_id: Int!,
  $input: MultipleActionOnOrdersInput!
){
  registerPaymentOrder(
    tenant_id:$tenant_id,
    input:$input
  ){
    __typename
    ...on Order{
      id
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const CANCEL_ORDER = `
mutation cancelOrder(
  $tenant_id: Int!,
  $input: MultipleActionOnOrdersInput!
){
  cancelOrder(
    tenant_id:$tenant_id,
    input:$input
  ){
    __typename
    ...on Order{
      id      
    }
    ...on ResultError{
      status_code
      message
      message_translation_key      
    }
  }
}
`;

export const SOLICIT_REVISION_OF_ORDER = `
mutation solicitRevisionOfOrder(
  $tenant_id: Int!,
  $input: MultipleActionWithReasonRequiredOnOrdersInput!
){
	solicitRevisionOfOrder(
    tenant_id:$tenant_id,
    input:$input
  ){
    __typename
    ...on Order{
      id
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const REGISTER_DISPATCH_ORDER = `
mutation registerDispatchOrder(
  $tenant_id: Int!,
  $input: RegisterDispatchInput!
){
  registerDispatchOrder(
    tenant_id:$tenant_id,
    input:$input
  ){
    __typename
    ...on Order{
      id
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const LABEL_DELIVERED = `
mutation labelDelivered(
  $tenant_id: Int!,
  $input: RegisterDevileryInput!
){
  labelDelivered(
    tenant_id: $tenant_id,
    input: $input
  ){
    __typename
    ...on Order{
      id
      
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const LABEL_RETURNED = `
mutation labelReturned(
  $tenant_id: Int!,
  $input: RegisterDevileryInput!
){
  labelReturned(
    tenant_id:$tenant_id,
    input:$input
  ){
    __typename
    ...on Order{
      id      
    }
    ...on ResultError{
      status_code
      message
      message_translation_key    
    }
  }
}
`;

export const GENERATE_TICKET = `
mutation generateTicketOrder(
  $tenant_id: Int!,
  $input: MultipleActionOnOrdersInput!
){
  generateTicketOrder(
    tenant_id:$tenant_id,
    input:$input
  ){
    __typename
    ...on Order{
      id
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const CANCEL_TICKET = `
mutation cancelTicketOrder(
  $tenant_id: Int!,
  $input: MultipleActionOnOrdersInput!
){
  cancelTicketOrder(
    tenant_id:$tenant_id,
    input:$input
  ){
    __typename
    ...on Order{
      id
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;

export const PRINT_TICKET_ORDER = `
mutation printTicketOrder(
  $tenant_id:Int!,
  $input: MultipleActionOnOrdersInput!
){
  printTicketOrder(
    tenant_id:$tenant_id,
    input:$input
  ){
    __typename
    ...on Order{
      id
      pdf
    }
    ...on ResultError{
      status_code
      message
      message_translation_key      
    }  
  }
}
`;
