export const STATS = `
query stats(
  $tenant_id: Int!,
  $filter: FilterStatsInput
){
  stats(
    tenant_id:$tenant_id,
    filter:$filter
  ){
    __typename
    ...on Stats{
      order_stats{
        pending_amount
        confirmed_amount
        dispatched_amount
        rejected_amount
        delivered_amount
        returned_amount
        canceled_amount
      }
      product_stats{
        ranking{
          product{
            id
            name
            code
            product_picture{
              filename
              url
            }
          }
          amount_sold
        }
      }
      money_stats{
        delivered_order_total
        delivered_shipping_total
        delivered_product_cost_total
        delivered_commission_total
        total_earnings
        returned_shipping_total
      }
    }
    ...on ResultError{
      status_code
      message
      message_translation_key
    }
  }
}
`;
